<template >
  <div>
    <b-card-code :title="name">
        <button type="button" class="btn ml-auto btn-primary m-1">
                <router-link class="text-white" :to="'/eca/vulnerability/edit/' + id">Edit</router-link>
        </button>
    <button
      v-if="is_reviewed"
      type="button"
      class="btn ml-auto btn-primary m-1"
      @click="publishCVD(id, false)"
    >
       Unpublish
    </button>
    <button
      v-else
      type="button"
      class="btn ml-auto btn-primary m-1"
      @click="publishCVD(id, true)"
    >
       Publish
    </button>
      <div>
        <h5 class="text-primary">VM Name</h5>
        <p>{{vm_name}}</p>
      </div>
      <div>
        <h5 class="text-primary">OWASP Category</h5>
        <p>{{owasp_category}}</p>
      </div>
      <div>
        <h5 class="text-primary">CVSS Score</h5>
        <p>{{cvss_score}}</p>
      </div>
      <div>
        <h5 class="text-primary">Severity</h5>
        <p>{{severity}}</p>
      </div>
      <div>
        <h5 class="text-primary">Description</h5>
        <p>{{description}}</p>
      </div>
      <div>
        <h5 class="text-primary">Synopsis</h5>
        <p>{{synopsis}}</p>
      </div>
      <div>
        <h5 class="text-primary">Recommendations</h5>
        <p>{{recommendation}}</p>
      </div>
      <div>
        <h5 class="text-primary">References</h5>
        <p>{{references}}</p>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

export default {
  components: {
    BCardCode,
  },
  data: function () {
    return {
      id: this.$route.params.id,
      name: "",
      vm_name: "",
      synopsis: "",
      description: "",
      cvss_score: "",
      owasp_category: "----",
      recommendation: "----",
      references: "----",
      severity: "",
      is_reviewed: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "master/vulnerability/" +
          this.$route.params.id, //+'/vuln-details/',
      };
      var self = this;
      this.$http(o_options).then((res) => {
        let data = res.data
        self.name = data.name
        self.vm_name = data.vm_name
        self.description = data.description
        self.synopsis = data.synopsis
        self.recommendation = data.recommendation
        self.cvss_score= data.cvss_score
        self.owasp_category = data.owasp_category
        self.severity = data.severity
        self.is_reviewed = data.is_reviewed
      });
    },
    publishCVD: function (id, value) {
      if (
        confirm("Please confirm this action")
      ) {
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: {"is_reviewed":value},
            url:
              process.env.VUE_APP_BASEURL +
              "master/vulnerability/" +
              id +
              "/publish/",
          };
        this.$http(options).then((res) => {
          // console.log(res.data._id);
          // if (res.data._id) {
            this.load();
          // }
        });
      }
    },
  },
};
</script>